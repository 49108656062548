<template>
  <router-view />
</template>

<style lang="scss">
body {
  background: #171417;
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-height: 100vh;
  max-width: 100vw;
}
* {
  box-sizing: border-box;
  user-select: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  align-items: center;
  position: relative;
  margin: auto;
  height: 100vh;
}
main {
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  display: flex;
  justify-content: center;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100px;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
@supports (-webkit-touch-callout: none) {
  #app {
    min-height: -webkit-fill-available;
  }
  body {
    max-height: -webkit-fill-available;
  }
}
</style>
