import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/drapeaux",
    name: "drapeaux",
    component: () =>
      import(/* webpackChunkName: "drapeau" */ "../views/DrapeauView.vue"),
  },
  {
    path: "/capitales",
    name: "capitales",
    component: () =>
      import(/* webpackChunkName: "capital" */ "../views/CapitalView.vue"),
  },
  {
    path: "/departements",
    name: "departements",
    component: () =>
      import(
        /* webpackChunkName: "departements" */ "../views/DepartementView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
